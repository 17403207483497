.swiper-slide-container{
    .active-image{
        border: 5px solid;
        border-radius: 10px;
        border-image-source: linear-gradient(113.88deg, #B7FFFB -0.09%, #00232B 53.91%);
        border-image-slice: 1;
        @media (max-width:767px) {
            border: 2.5px solid;
            border-image-source: linear-gradient(113.88deg, #B7FFFB -0.09%, #00232B 53.91%);
            border-image-slice: 1;
         }
    }
    .overlay{
        background: linear-gradient(270deg, #000000 -3.11%, #2A2A2A 46.9%, #575757 100%);
    }
}