.committee-page-container {
    background-image: url('../../assets/images/CommitteePageBackground.webp');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: calc(100vh - 80px);
    max-height: 100%;
    background-position: center;
    .glow-bar-left {
      height: 6px;
      background: linear-gradient(to right, #477ef9, #9dbbff, #00d94a);
      width: 50px;
      border-radius: 3px 0 0 3px;
      margin-right: 4px;
    }
    .glow-bar-right {
      height: 6px;
      background: linear-gradient(to right, #477ef9, #9dbbff, #00d94a);
      width: 50px;
      border-radius: 0 3px 3px 0;
    }

    
  }

.text-gradient{
    background: -webkit-linear-gradient(#fff, #999);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }

  @media (max-width:556px) {
  .glow-bar-left {
    height: 4px;
    width: 30px;
  }
  .glow-bar-right {
    height: 4px;
    width: 30px;
  }
  }