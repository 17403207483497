.image-container img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
@media(1024px<=width<=1280px){
    .description-container{
        gap:20px;
        width:900px;
        height:350px;
    }
    .description-inner-container{
        width:700px;
        height:300px;
    }
    .description-theme{
        width:700px;
        font-size: 30px;
    }
    .description-caption{
        width:700px;
        flex-direction: column;
    }
    .image-container{
        width:151px;
        height:146px;
    }
}