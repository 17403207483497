.flow-container{
    height: 150px;
    overflow: hidden;
    transition: height 1s ease-out;
}
.collapsed{
    height: 0;
    overflow: hidden;
    transition: height 1s ease-out;
}
@media (max-width:767px) {
    .flow-container{
        height: 500px;
        overflow: hidden;
        &.collapsed{
            height: 0;
            overflow: hidden;
            transition: height 1s ease-out;
        }
    }
}
