@import "../../core/scss/variables";

button {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:12px
}

.border-mask-home {
  min-height: 60px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.6) 0.01%, rgba(255, 255, 255, 0) 99.99%, rgba(255, 255, 255, 0.1) 100%);
  position: relative;
  padding: 0;
  border-radius: 40px;
  cursor: pointer;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;

}

.border-mask-home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 40px;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gradient-border-mask-home::before {
  background: linear-gradient(177.6deg, #01D84C 1.86%, rgba(71, 126, 249, 0.5) 49.82%) border-box;
  -webkit-mask: linear-gradient($white 0 0) padding-box, linear-gradient($white 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.text{
  font-family: 'Good Times', sans-serif;
  text-align: center;
  font-size: 13px;
  background: linear-gradient(90deg, #FFFFFF 42%, #999999 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
