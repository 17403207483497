@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.header {
  display: flex;
  height: $nav-bar-height;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 30;
  @include glass-texture;
  clip-path: polygon(
    7% 95%,
    0 95%,
    0 0,
    100% 0,
    100% 0,
    100% 95%,
    93% 95%,
    91% 60%,
    62.5% 60%,
    60% 95%,
    40% 95%,
    37.5% 60%,
    9% 60%
  );
}
.hamburger-icon {
  @include center-content;
  width: 5%;
  height: $nav-bar-height;
  .hamburger-react {
    height: 50% !important;
    div {
      border-radius: 15px;
      background-image: linear-gradient(to right, #477ef9, #00d453) !important;
    }
    div:first-child {
      width: 70% !important;
    }
    div:nth-child(2) {
      width: 100% !important;
    }
    div:nth-child(3) {
      width: 85% !important;
    }
  }
}
.cng-logo {
  @include center-content;
  height: $nav-bar-height;
}
.cdw-logo {
  @include center-content;
  height: $nav-bar-height;
  // .faq-button {
  //     margin: 0 36px 0 20px;
  //     .border-mask-home {
  //         min-height: 30px;
  //     }
  //     .text {
  //         font-size: 18px;
  //     }
  //     .logo {
  //         width: 30px;
  //         border-radius: 50%;
  //         border: 1px solid white;
  //         background: -webkit-linear-gradient(55deg, #477ef9, #00d94a);
  //         font-weight: 900;
  //     }
  // }
  .mobile-faq-logo {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .header {
    padding-top: 1.8rem;
    clip-path: polygon(
      0 0,
      100% 0,
      100% 100%,
      88% 100%,
      80% 73%,
      20% 73%,
      12% 100%,
      0 100%
    );
  }
  .mobile-faq-logo {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 14px;
    border-radius: 50%;
    border: 1px solid white;
    color: white;
    margin: 0 10px 0;
  }
  .faq-button {
    display: none;
  }
  .hamburger-icon {
    width: 3.5rem;
    display: block;
    .hamburger-react {
      width: 37px !important;
    }
  }
  .cng-logo{
    display: block;
  }
  .cdw-logo {
    display: block;
    width: 3.2rem;
    img {
      object-fit: contain;
      height: 50%;
      width: 100%;
    }
  }
}
