#carousel-wrapper{
  width: 70%;
  height: 200px;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
} 

@media (max-width:767px) {
  #carousel-wrapper{
    width: 100%;
    margin-top:-10px;
  }
}