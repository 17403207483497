
@mixin team-name-border($hor, $ver , $height ){
    position: relative;
    &::before{
        content: "";
        height: 5px;
        width: 50%;
        background: linear-gradient(90deg, #477EF9 0%, #01D84C 100%);
        display: block;
        position: absolute;
        top: calc( $ver +  $height  );
        left: calc( $hor);
    }
    &::after{
        content: "";
        height: 5px;
        width: 50%;
        background: linear-gradient(90deg, #477EF9 0%, #01D84C 100%);
        display: block;
        position: absolute;
        bottom: calc( $ver +  $height );
        right: calc( $hor);
    }
}

.single-team{
    .team-members-list{
        background: rgba(80, 80, 80, 0.25);
        box-shadow: 0px 0px 30px 10px rgba(59, 156, 212, 1);
        .team-members-inner-list{
            gap: calc(25% / 2);
            row-gap: 25px;
            .member{      
                flex-basis: 25%;
             .member-image-container{
                width: 100%;
                height: 160px;
                img{
                    object-position: top;
                }
             }
             
             .member-name{
                line-height: 1.3rem;
                 font-weight: 600;
             }
         }

        }
        .team-name-box{
            background: rgba(128, 227, 188, 0.1);

            .team-name{
                @include team-name-border(-1rem , -0.75rem , -2.5px);
    
            }
        }
        
    }
    .team-name-container{
        .team-name-box{
            background: rgba(128, 227, 188, 0.1);
            .team-name{
                @include team-name-border(-2rem , -1rem , -2.5px);
    
            }
        }
    }
}
@media (max-width:1220px) {
    .single-team{
        .team-members-list{
            box-shadow: 0px 0px 15px 5px rgba(59, 156, 212, 1);
            .team-members-inner-list{
                font-size: 12px;
                .member{
                    .member-image-container{
                        height: 120px;
                        width: 110px;
                     }
                    .member-name{
                        font-size: 14px;
                    }
                }
                
            }
            
        }
    }
}
@media (max-width:767px) {
    .single-team{
        .team-members-list{
            .team-members-inner-list{
                .member{   
                    flex-basis: 25%;
                    .member-image-container{
                        width: 75px;
                        height: 75px;
                    }
                    .member-name{
                        width: 100%;
                        font-size: 12px;
                        line-height: 1rem;
                    }
                }
            }  
        }
    }
}

@media (max-width:480px) {
    .single-team{
        .team-members-list{
            .team-members-inner-list{
                row-gap: 25px;
                .member{  
                    flex-basis: 20%;
                    .member-image-container{
                        width: 75px;
                        height: 75px;
                    }
                    .member-name{
                        font-size: 11px;
                    }
                }
            }  
            .team-name{
                font-size: 16px;
            }
        }
    }
}