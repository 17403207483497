.side-navbar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 123450;

    @media (max-width: 576px) {
        display: none;
    }
}

.side-navbar-container{
    position: fixed;
    z-index: 123456;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 20%;
    overflow: scroll;
    justify-content: space-evenly;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(20px);
    -ms-overflow-style: none;  
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display: none;
    }
    @media (max-width: 576px) {
        width: 100%;
    }

    @media only screen and (min-width:576px) and (max-width:1000px){
        width: 35%;
    }
}


.side-navbar {
    // width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 30px 0; // layout after commenting participants , events , event winners
    // height: 100vh;
    // position: fixed;
    // top: -30px;
    // left: 0;
    // bottom: 0;
    // right: 0;
    // z-index: 25;

    @media (max-width: 576px) {
        width: 100%;
        top: 0;
    }

    .side-navbar-logo-wrapper {
        padding: 0px 1.563rem;

        // position: relative;
        @media (max-width: 576px) {
            padding-top: 15px;
            padding-left: 36px;
        }

        .side-navbar-logo-icon {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 576px) {
                gap: 30px;
                justify-content: normal;
                align-items: center;
            }

            .side-navbar-logo-details {
                @media (max-width: 576px) {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }

                img {
                    // height: 75px;
                    // width: 75px;
                    width: 60px;
                    height:60px;
                }

                .cng-text-icon {
                    width: 60%;
                    display: none;

                    @media (max-width: 576px) {
                        display: block;
                        width: 55%;

                    }
                }
            }

            .left-arrow-icon {
                // display: none;
                cursor: pointer;
                @media (max-width: 576px) {
                    // display: block;
                    width: 10%;
                    cursor: pointer;
                }
            }
        }

    }
}

.trapezoid {
    // height: 150px;
    // width: 70px;
    // transform: skewY(-30deg);
    width: 21%;
    height: 9vw;
    position: fixed;
    top: -3%;
    // left: 20%;
    right: -21%;
    // border-radius: 5px;
    // -webkit-backdrop-filter: blur(20px);
    // backdrop-filter: blur(20px);
    @media (max-width: 576px) {
        display: none;
    }
    .trapezoid-wrapper{
        width: 100%;
        height: 100%;
        transform: skewY(-30deg);
        border-radius: 5px;
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        background-color: rgba(255, 255, 255, 0.15);
        img {
            position: absolute;
            top: 50%;
            left: 31%;
            height: 30px;
            width: 45%;
            transform: skewY(30deg);
    
        }
    }
    
}

.side-navbar-items {
    display: flex;
    align-items: center;
    margin: 0.625rem 0;
    width: 100%;
    @media (max-width: 576px) {
        gap: 20px;
    }
    

    img {
        margin-right: 1.563rem;
        width: 15%;
        height: 40px;

        @media (max-width: 576px) {
            width: 35px;
            height: 35px;
        }
        @media only screen and (min-width:576px) and (max-width:1000px){
            width: 30px;
            height: 30px;
        }
    }
    .hover-under{
        display: none;
    }
    &:hover{
        .hover-under{
            display: block;
        }
    }

    .side-navbar-item-name {
        a {
            text-decoration: none;
            font-size: 0.8rem;
            color: white;
            cursor: pointer;

            @media (max-width: 576px) {
                font-size: 1.25rem;
            }
            @media only screen and (min-width:576px) and (max-width:1000px){
                font-size: 0.8rem;
            }
        }
    }


}

.under::after {
    margin-top: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    content: "";
    height: 3px;
    background: linear-gradient(to right, #477ef9, #9dbbff, #00d94a);
    border-radius: 3px;
    width: 40px;
    // display: none;
}

.side-navbar-links {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.show {
    display: flex;
    transition: 0.9s;
}

// .showTrapezoid{
//     transition: 1.24s;
// }

.hide {
    transition: 2.2s;
    display: flex;
    left: -560px;
}

// .hideTrapezoid{
//     transition: 3.05s;
//     left: -560px;
// }

.addHighlight{
    display: block;
}

.removeHighlight{
    display: none;
}

.showOverlay{
    display: block;
    transition: 3s;
}

.hideOverlay{
    display: none;
}