@import "../../core/scss/variables";
@import "../../core/scss/mixins";
@import "../../styles/variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.home-section-wrapper {
  height: 100vh;

  .video-wrapper {
    width: 100vh;
    height: 100%;
  }
}
.glow-circle {
  font-size: 28rem;
  font-weight: 1000;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 7px #3761c1, 0 0 10px #3761c1, 0 0 21px #3761c1,
    0 0 42px #3761c1, 0 0 82px #3761c1, 0 0 92px #3761c1, 0 0 102px #3761c1,
    0 0 151px #3761c1;
  z-index: 1;
  pointer-events: none;
}

.glow-circle-hover {
  font-size: 35rem;
  text-shadow: 0 0 15px #3761c1, 0 0 180px #3761c1, 0 0 220px #3761c1,
    0 0 250px #3761c1, 0 0 280px #3761c1, 0 0 302px #3761c1, 0 0 352px #3761c1,
    0 0 382px #3761c1;
}

@media (max-width: 1023px) {
  .glow-circle {
    font-size: 21rem;
  }
  .glow-circle-hover {
    font-size: 25rem;
  }
}

@media (max-width: 767px) {
  .glow-circle {
    font-size: 15rem;
  }
  .glow-circle-hover {
    font-size: 18rem;
  }
}

.logo-image-container {
  position: relative;
}

.logo-image-home {
  position: relative;
  z-index: 2;
}

.button-container-home {
  z-index: 2;
}

.card-left {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 0;
  transform: rotate(90deg);
  margin-left: -14vw;
}
.card-right {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: -50%;
  right: -74%;
  transform: rotate(-90deg);
}

.bottom-card-part {
  position: absolute;
  width: 100%;
  background-color: rgba(13, 22, 36, 0.8);
  backdrop-filter: blur(15px);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  p {
    font-size: 4vw;
    font-weight: bold;
    font-family: "Good Times", sans-serif;
    color: white;
    background: linear-gradient(90deg, #ffffff 42%, #999999 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.top-card-part {
  position: absolute;
  top: -4vw;
  width: 40%;
  left: 50%;
  transform: translateX(-50%);
  height: 4vw;
  background-color: rgba(13, 22, 36, 0.8);
  backdrop-filter: blur(15px);
  border-top-right-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  text-align: center;

  p {
    font-size: 1.25vw;
    font-weight: bold;
    font-family: "Good Times", sans-serif;
    color: white;
    background: linear-gradient(90deg, #ffffff 42%, #999999 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.left-curve {
  content: "";
  position: absolute;
  top: -1.4vw;
  width: 1.6vw;
  height: 1.4vw;
  background-color: transparent;
  border-bottom-right-radius: 3.2vw;
  box-shadow: 0.4vw 0.3vw 0 0.25vw rgba(13, 22, 36, 0.8);
  z-index: 1;
  left: 10.4vw;
}

.right-curve {
  content: "";
  position: absolute;
  top: -1.4vw;
  width: 1.6vw;
  height: 1.4vw;
  background-color: transparent;
  right: 10.4vw;
  border-bottom-left-radius: 3.2vw;
  box-shadow: -0.4vw 0.3vw 0 0.25vw rgba(13, 22, 36, 0.8);
  z-index: 1;
}

.down-button {
  position: relative;
  cursor: pointer;
  top: 12%;
}

// REWIND SECTION
.rewind-section {
  min-height: 75vh;
  background: url("../../assets/images/grid-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .text-gradient-logo {
    background: -webkit-linear-gradient(#fff, #999);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .desktop-rewind-btn {
    transform: translate(45%, 35%);
  }
}

.home-page-events-container {
  background-image: url("../../assets/images/home-page-events-bg.webp");
  .glow-bar {
    height: 3px;
    background: linear-gradient(to right, #477ef9, #9dbbff, #00d94a);
    flex-basis: 5%;

    border-radius: 0 3px 3px 0;
  }
  .the-text {
    @include home-events-left-text(
      linear-gradient(90deg, #ffffff 46.5%, #999999 100%),
      text,
      text,
      transparent
    );
  }

  .events-text {
    @include home-events-left-text(
      linear-gradient(90deg, #477ef9 40%, #05d257 100%),
      text,
      text,
      transparent
    );
  }

  .event-sum {
    line-height: 3.5rem;
  }

  .know-more-btn {
    @include home-events-left-text(
      linear-gradient(90deg, #ffffff 75%, #999999 100%),
      text,
      text,
      transparent
    );
  }

  .participant-count {
    font-size: 80px;

    span.text-130 {
      @include home-events-left-text(
        linear-gradient(180deg, #ffffff 48%, #999999 100%),
        text,
        text,
        transparent
      );
    }

    span.text-plus {
      color: rgba(1, 216, 76, 1) !important;
      margin-left: -7px;
    }
  }

  .right-side {
    background-image: url("../../assets/images/home-events-right-side-bg.webp");
    background-size: 100% 100vh;
    transform: skewX(-10deg);
    right: -4.7%;

    .blue-card {
      background-color: rgba(0, 34, 59, 0.8);
    }

    .top-section {
      height: 90%;

      .top-content {
        padding: 5%;

        .icon-container {
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(8px);
          -webkit-backdrop-filter: blur(8px);

          img {
            width: 71.6px;
            height: 75.26px;
          }
        }
      }

      .mid-content {
        height: 60%;
        padding: 6rem 0;

        .text-one {
          @include home-events-right-text(
            rgba(255, 255, 255, 20%),
            15rem,
            -3rem
          );
        }

        .text-two {
          @include home-events-right-text(
            rgba(255, 255, 255, 20%),
            8rem,
            -1.5rem
          );
        }
      }
    }

    .bottom-section {
      height: 10%;

      .think-a-thon-wrapper {
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);

        img {
          transform: rotate(0deg);
        }

        span.think {
          font-size: 30px;
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: white;
        }

        span.thon {
          font-size: 30px;
          @include home-events-left-text(
            linear-gradient(180deg, #ffffff 48%, #999999 100%),
            text,
            text,
            transparent
          );
        }
      }
    }

    .black-border {
      width: 3%;
      background-color: #000000;
    }

    .green-card {
      background-color: rgba(5, 91, 57, 0.8);

      .mid-content > .text-two {
        padding-right: 12%;
      }

      span.code {
        font-size: 30px;
        @include home-events-left-text(
          linear-gradient(180deg, #ffffff 48%, #999999 100%),
          text,
          text,
          transparent
        );
      }

      span.code-thon {
        font-size: 30px;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;
      }
    }
  }
  .participant-wrapper{
    img{
      padding-top: 10px;
    }
  }
}

@media (max-width: 540px) {
  .home-section {
    padding-top: 6rem;
  }
  .card-left {
    transform: rotate(0deg);
    left: 0;
    margin-left: -5%;
    width: 80%;
    top: 17%;
    .top-card-part {
      left: 25%;
    }
  }
  .down-button {
    top: 25%;
  }
  .participant-wrapper{
    padding: 4.5rem;
  }
  .about-event-background{
    img{
      object-fit: cover;
    }
  }
  .card-right {
    transform: rotate(0deg);
    right: -13%;
    margin-right: 0;
    width: 87%;
    top: -16%;

    .top-card-part.card-right-br {
      right: 0;
      left: auto;
      transform: translateX(0);
      width: 50%;
    }
  }

  .bottom-card-part {
    border-radius: 0.5rem;
    p {
      font-size: 7vw;
    }
  }

  .top-card-part {
    top: -8vw;
    width: 50%;
    height: 8vw;

    p {
      font-size: 3vw;
    }
  }

  .card-right-container {
    position: absolute;
    top: 82%;
    width: 80%;
    right: 0;
  }

  .left-curve {
    top: -3.2vw;
    width: 4vw;
    height: 3.2vw;
    border-bottom-right-radius: 8vw;
    box-shadow: 1vw 0.8vw 0 0.6vw rgba(13, 22, 36, 0.8);
    left: 20vw;
  }

  .right-curve {
    top: -3.2vw;
    width: 4vw;
    height: 3.2vw;
    border-bottom-left-radius: 8vw;
    box-shadow: -1vw 0.8vw 0 0.6vw rgba(13, 22, 36, 1);
    right: 36.2vw;
  }

  .card-right-br {
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
  }
  .card-right-left-curve {
    left: 39.56vw;
  }
  .card-right-right-curve {
    right: 10vw;
  }

  .know-more-btn {
    font-size: 9px;
  }

  .middle-section {
    position: relative;
    background-color: #000000;

    .blue-card {
      width: 100%;
      height: 100%;
      background-size: 100% 100% !important;
      background: linear-gradient(
          0deg,
          rgba(0, 34, 59, 0.8),
          rgba(0, 34, 59, 0.8)
        ),
        url("../../assets/images/home-events-right-side-bg.webp");
      clip-path: polygon(95% 0%, 0% 100%, 0% 0%);
      position: absolute;

      .mv-think-a-thon-txt-wrapper {
        width: 230px;
        height: 66px;
        border-bottom-right-radius: 1rem;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);

        img {
          width: 36px;
          height: 36px;
        }

        span.think {
          font-size: 20px;
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: white !important;
        }

        span.thon {
          font-size: 20px;
          @include home-events-left-text(
            linear-gradient(180deg, #ffffff 48%, #999999 100%),
            text,
            text,
            transparent
          );
        }
      }

      .mv-think-a-thon-wrapper {
        width: 150px;
        height: 117px;
        background: rgb(41 64 83 / 85%);
        padding: 5px 0px 0px 10px;
        position: absolute;
        bottom: 0;
        clip-path: polygon(85% 0%, -8% 100%, 0% 0%);
        margin: 0 0 1rem 0.6rem;
        overflow: hidden;

        img {
          width: 52px;
          height: 54px;
        }
      }
    }

    .green-card {
      width: 100%;
      height: 100%;
      background-size: 100% 100% !important;
      background: linear-gradient(
          0deg,
          rgba(5, 91, 57, 0.8),
          rgba(5, 91, 57, 0.8)
        ),
        url("../../assets/images/home-events-right-side-bg.webp");
      clip-path: polygon(100% 0%, 5% 100%, 100% 100%);

      .mv-code-a-thon-wrapper {
        width: 150px;
        height: 117px;
        background: rgb(41 99 73);
        // backdrop-filter: blur(20px);
        // -webkit-backdrop-filter: blur(20px);
        padding: 5px 0px 0px 10px;
        position: absolute;
        right: 8px;
        top: 13px;
        clip-path: polygon(100% 0%, 12% 100%, 100% 100%);
        margin-top: 10px;
        overflow: hidden;

        img {
          width: 52px;
          height: 54px;
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 0 10px 10px 0;
        }
      }

      .mv-code-a-thon-txt-wrapper {
        width: 230px;
        height: 66px;
        border-top-left-radius: 1rem;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        position: absolute;
        bottom: 0;
        right: 0;

        img {
          width: 36px;
          height: 36px;
        }

        span.code {
          font-size: 20px;
          @include home-events-left-text(
            linear-gradient(180deg, #ffffff 48%, #999999 100%),
            text,
            text,
            transparent
          );
        }

        span.code-thon {
          font-size: 20px;
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: white;
        }
      }
    }
  }

  .participant-count {
    font-size: 40px !important;

    span.text-130 {
      font-size: 40px !important;
      @include home-events-left-text(
        linear-gradient(180deg, #ffffff 48%, #999999 100%),
        text,
        text,
        transparent
      );
    }

    span.text-plus {
      color: rgba(1, 216, 76, 1) !important;
      margin-left: -1rem;
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 540px) {
  .the-text {
    font-size: 2rem;
    line-height: normal;
    @include home-events-left-text(
      linear-gradient(90deg, #ffffff 46.5%, #999999 100%),
      text,
      text,
      transparent
    );
  }

  .events-text {
    font-size: 4.5rem;
    line-height: normal;
    @include home-events-left-text(
      linear-gradient(90deg, #477ef9 40%, #05d257 100%),
      text,
      text,
      transparent
    );
  }

  .txt {
    font-size: 1.2rem;
  }

  .know-more-btn {
    margin-right: 2rem;
  }

  .middle-section {
    margin-top: 2rem;
    position: relative;
    background-color: #000000;

    .blue-card {
      width: 100%;
      height: 100%;
      background-size: 100% 100% !important;
      background: linear-gradient(
          0deg,
          rgba(0, 34, 59, 0.8),
          rgba(0, 34, 59, 0.8)
        ),
        url("../../assets/images/home-events-right-side-bg.webp");
      clip-path: polygon(95% 0%, 0% 100%, 0% 0%);
      position: absolute;

      .mv-think-a-thon-txt-wrapper {
        width: 50%;
        height: 20%;
        border-bottom-right-radius: 1rem;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(20px);

        img {
          width: 50px;
          height: 50px;
        }

        span.think {
          font-size: 2rem;
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: white !important;
        }

        span.thon {
          font-size: 20px;
          @include home-events-left-text(
            linear-gradient(180deg, #ffffff 48%, #999999 100%),
            text,
            text,
            transparent
          );
        }
      }

      .mv-think-a-thon-wrapper {
        width: 17rem;
        height: 10rem;
        background: rgba(41, 64, 83, 0.85);
        padding: 15px 0px 0px 25px;
        position: absolute;
        bottom: 0;
        clip-path: polygon(100% 0%, -8% 100%, 0% 0%);
        margin: 0 0 1rem 1rem;
        overflow: hidden;

        img {
          width: 5rem;
          height: 5rem;
        }
      }
    }

    .green-card {
      width: 100%;
      height: 100%;
      background-size: 100% 100% !important;
      background: linear-gradient(
          0deg,
          rgba(5, 91, 57, 0.8),
          rgba(5, 91, 57, 0.8)
        ),
        url("../../assets/images/home-events-right-side-bg.webp");
      clip-path: polygon(100% 0%, 5% 100%, 100% 100%);

      .mv-code-a-thon-wrapper {
        width: 17rem;
        height: 10rem;
        background: rgb(41, 99, 73);
        /* padding: 5px 0px; */
        position: absolute;
        right: 15px;
        top: 16px;
        clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
        margin-top: 10px;
        overflow: hidden;

        img {
          width: 5rem;
          height: 5rem;
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 0 15px 15px 0;
        }
      }

      .mv-code-a-thon-txt-wrapper {
        width: 50%;
        height: 20%;
        border-top-left-radius: 1rem;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        position: absolute;
        bottom: 0;
        right: 0;

        img {
          width: 50px;
          height: 50px;
        }

        span.code {
          font-size: 2rem;
          @include home-events-left-text(
            linear-gradient(180deg, #ffffff 48%, #999999 100%),
            text,
            text,
            transparent
          );
        }

        span.code-thon {
          font-size: 2rem;
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: white;
        }
      }
    }
  }
}

.dummy-btn {
  width: 50px;
  height: 20px;
  text-align: center;
}

@media (max-width: 375px) {
  .card-left {
    top: 21%;
  }
  .button-container-home {
    .border-mask-home {
      min-height: 50px;
    }
  }
  .down-button {
    top: 20%;
  }
  .participant-wrapper{
    padding:1.5rem;
  }
}
