.status-page-container {
  background-image: url("../../assets/images/StatusPageBackground.webp");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-attachment: fixed;
  overflow-y: auto;
  box-sizing: border-box;

  .header-align {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 100px;

    h1 {
      letter-spacing: 4px;
    }
  }

  .status-header {
    h1 {
      letter-spacing: 4px;
    }
  }
  .participants-header-item{
    @media (max-width:450px) {
      font-size: 12px;
      font-weight: 900;
    }
    @media (max-width:300px) {
      font-size: 10px;
    }
    &.active{
      background: rgba(199, 199, 199, 0.25);
    }
  }
}
a{
  text-decoration: underline;
  cursor: pointer;
}
.team-details-container {
  margin: 0 5%;
  gap: 4%;
}

.column {
  width: 30.66%;
}

@media (max-width: 920px) {
  .status-page-container {
    .header-align {
      margin-top: 100px;
      .check-header {
        h1 {
          font-size: 32px;
        }
      }
      .header-img {
        img {
          height: 45px;
        }
      }
    }

    .status-header {
      margin-top: 20px;
      justify-content: center;

      // .status-header-text {
      //   width: auto;
      // }

      h1 {
        font-size: 32px;
      }
    }

    .counter-container {
      h1 {
        font-size: 32px;
      }

      span {
        font-size: 6px;
      }

      .counter-wrapper {
        padding-left: 15px;
        padding-right: 15px;
      }

      .counter-wrapper:not(:last-child) {
        border-right: 1px solid #ffffff;
      }
    }
  }
}

@media (max-width: 640px) {
  .status-page-container {
    background-image: url("../../assets/images/team-status-mobile.webp");

    .header-align {
      margin-top: 100px;
      .check-header {
        h1 {
          font-size: 22px;
        }
      }
    }

    .status-header {
      margin-top: 20px;
      justify-content: center;

      .status-header-text {
        width: auto;
      }

      h1 {
        font-size: 22px;
        margin-left: 0px;
      }
    }

    .counter-container {
      h1 {
        font-size: 22px;
      }

      span {
        font-size: 8px;
      }

      .counter-wrapper {
        padding-left: 15px;
        padding-right: 15px;
      }

      .counter-wrapper:not(:last-child) {
        border-right: 1px solid #ffffff;
      }
    }
  }
}
