/* Scss Mixins */

// Home Pages
@mixin home-events-right-text($color, $fs, $mr) {
  color: $color;
  font-size: $fs;
  margin-right: $mr;
}

@mixin home-events-left-text($bg, $wb-bc, $mo-bc, $wb-fc) {
  background: $bg;
  -webkit-background-clip: $wb-bc;
  -moz-background-clip: $mo-bc;
  -webkit-text-fill-color: $wb-fc;
}