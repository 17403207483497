.image-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
@media (1024px<=width<=1280px) {
  .image-container {
    left: 225px;
    top: 10px;
  }
}
