.main{
    position:absolute;
    z-index: 1;
    width:100%;
    height:100%;
    background-color: #1a1a1a;
    //display:flex;

}
.boxes{
    position:relative;
    width:30px;
    height:30px;
    background-color:#1a1a1a;
    float:left;

  }
  .circle{
    position:absolute;
    width:6%;
    height:6%;
    left:53%;
    top:53%;
    opacity:0.5;
    border-radius:50%;
    background-color:#adadad;
  }