.events-wrapper{
  // height:100%;
}
.events-page-container {
  display: flex;
  width:100%;
  height:100vh;
  position:fixed;
  top:30px;
}
.left-container {
  width: 30%;
  min-width:300px;
  background-image: url("../../assets/images/grid-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex; 
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  gap: 14px;
  .logo-image{
    margin-left: 1.5rem;
  }
}
.event-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .glow-bar {
    height: 6px;
    background: linear-gradient(to right, #477ef9, #9dbbff, #00d94a);
    width: 50px;
    border-radius: 0 3px 3px 0;
  }
}

.event-text{
  font-family: 'Good Times', sans-serif;
  background: linear-gradient(90deg, #FFFFFF 0%, #BCBCBC 49.5%, #FFFFFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.5rem;
  letter-spacing: 1rem;
  @media (max-width:1425px) {
    font-size: 2rem;
  }
  @media (max-width:1200px) {
    font-size: 1.6rem;
  }
}

.code-text {
  font-family: 'Good Times', sans-serif;
  font-size: 1rem;
  color: #FFFFFF;
  letter-spacing: 0.2rem;
  padding-top: 2rem;
  @media (max-width:1425px) {
    font-size: .8rem;
  }
  @media (max-width:1200px) {
    font-size: .5rem;
  }
  .glow-bar {
    background: linear-gradient(to right, #477ef9, #9dbbff, #00d94a);
    height: 6px;
    width: 33px;
  }
}
.code-text > :nth-child(2){
  width: 11.2rem;
  border-radius: 3px 0 0 3px;
  margin-top: 6.5%;
  margin-bottom: 6.5%;
}

.event-container > :first-child {
  display: none;
  transform: rotate(180deg);
}

.right-container {
  background-image: url("../../assets/images/events-page-bg-desktop.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 70%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 767px) {
  .events-page-container {
    display: flex;
    flex-direction: column;
  }
  .event-container {
    justify-content: center; 
    align-items: center; 
    margin-bottom: 5%;
    .glow-bar {
      height: 6px;
      width: 33px;
    }
  }
  .logo-image{
    display: none;
  }
  .code-text > :nth-child(2){
    display: none;
  }
  .event-container > :first-child {
    display: block;
    transform: rotate(180deg);
  }
  .event-text{
    font-size: 18px;
    letter-spacing: 0.9rem;
    padding-left: 0.9rem;
    padding-right: 0rem;
  }

  .code-text {
    font-size: 15px;
    letter-spacing: 0.2rem;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .glow-bar {
      height: 6px;
      width: 33px;
    }
  }
  .left-container {
    width: 100%;
    height: 25%;
    background-image: url("../../assets/images/grid-bg.webp");
    background-size: cover;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .right-container {
    background-image: url("../../assets/images/events-page-bg-mobile.webp");
    background-size: cover;
    width: 100%;
  }
}

@media (max-width: 375px) {
  .right-container {
    // gap:70%
  }
}