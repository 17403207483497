.member-list-container{
  background-color: #001B2ACC;
  border-radius: 10px;
  box-shadow: 0px 0px 40px 10px rgba(0, 99, 170, 0.7);
  .inner-container{
    place-items: center;
    gap: 30px;
    @media (max-width:1400px) {
     gap: 40px;
    }
    @media (max-width:1024px) {
      gap: 30px;
     }
     @media (max-width:768px) {
      gap: 40px;
     }
    width: 100%;
    .individual-card{
      width: calc(25% - 90px + 2.5em);
      @media (max-width:1400px) {
        width: calc( 33.3333% - 80px);
      }
      @media (max-width:1024px) {
        width: calc( 25% - 90px + 2.5em );
      }
      @media (max-width:768px) {
        width: calc( 33.3333% - 80px + 2.5em);
      }
      @media (max-width:576px) {
        width: calc( 50% - 40px + 1.25em);
      }
      opacity: 0;
      &:hover{
        .fade-bg{
          background: linear-gradient(360deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
        }
        .member-name{
          display: block;
        }
      }
      .member-name{
        display: none;
      }
      @media (max-width:1400px) {
        // width: calc();
      }
    }
    &:after {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

/* Fade In animation keyframes: */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}