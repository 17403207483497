

@import "../../core/scss/variables";

.card-border {
    position: relative;
    cursor: pointer;
    border: 2px solid;
    border-image-slice: 1;
    border-width: 2px;
    background-image: linear-gradient(to bottom, rgb(0 0 0 / 10%), rgb(255 255 255 / 5%));
    backdrop-filter: blur(20px);
      .card-button{
        position: relative;
    }
  }

.thinkathon{
    .card-border {
        border-image-source:linear-gradient(0deg,$border-gradient-green, rgba($border-gradient-blue , 0.3) ) ;
    }
    .banner{
        height: 200px;
        width: 200px;
        transform: rotate(45deg) ;
        top: 115px;
        left: calc(50% - 100px);
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            background: #7FE3BC;
            box-shadow: 5px 5px 25px 8px #7FE3BC;
        }
        .banner-text{
            height: 100%;
            transform: rotate(-45deg) ;
        }
    }
    
}

.codeathon{
    .card-border {
        border-image-source:linear-gradient(0deg,$border-gradient-blue, rgba($border-gradient-green,0.3));
    }
    .banner{
        height: 250px;
        width: 250px;
        top: 80px;
        left: calc(100% / 2 - 125px);
        display: flex;
        align-items: center;
        justify-content: center;
        
        span{
            background: #9DBBFF;
            box-shadow: 5px 5px 25px 8px #9DBBFF;
        }
        .banner-text{
            height: 100%;
        }
    }
}

  
.banner{
    position: absolute;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    span:nth-child(1) {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 3px;
        animation: animate1 2s linear infinite;
    }
    span:nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 3px;
        animation: animate2 2s linear infinite;
        animation-delay: 1s;
    }
    span:nth-child(3) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 3px;
        animation: animate3 2s linear infinite;
    }
    span:nth-child(4) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 3px;
        animation: animate4 2s linear infinite;
        animation-delay: 1s;
    }
}



// animation for top Banner
@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

@keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(100%);
	}
}

@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

@keyframes animate4 {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(-100%);
	}
}