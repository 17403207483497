.status-animation {
  animation: bounce-in 3s ease infinite;
}
img {
  height: 36px;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 920px) {
  .status-border{

    width: 150px;
    margin-left: 1.5rem;

    img {
      height: 28px;
    }
    span{
      font-size: 18px;
    }
  }
}
