.prev-moments-section {
    background-image: url("../../assets/images/grid-bg.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Good Times", sans-serif;

    .txt-gradient-b-g {
        background: -webkit-linear-gradient(55deg, #477ef9, #00d94a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .content {
        height: 80%;
    }

    .center-position {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .txt-overlay {
        -webkit-text-stroke: 1px #fff;
        -webkit-text-fill-color: transparent;
    }

    .images {
        animation: 20s slider infinite linear;
    }

    @keyframes slider {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-100%);
        }
    }

    @media(min-width: 320px) {
        .txt-overlay {
            -webkit-text-stroke: 0.5px #fff;
        }

        .images {
            div:nth-child(1) {
                top: -40px;
                min-width: 80px;
                height: 80px;

            }

            div:nth-child(2) {
                bottom: 10px;
                min-width: 30px;
                height: 30px;

            }

            div:nth-child(3) {
                bottom: -60px;
                min-width: 50px;
                height: 50px;

            }

            div:nth-child(4) {

                min-width: 150px;
                height: 150px;

            }

            div:nth-child(5) {
                top: -40px;
                min-width: 20px;
                height: 20px;

            }

            div:nth-child(6) {
                bottom: 10px;
                min-width: 120px;
                height: 120px;

            }

            div:nth-child(7) {
                bottom: -30px;
                min-width: 120px;
                height: 120px;

            }

            div:nth-child(8) {

                min-width: 40px;
                height: 40px;

            }


            div:nth-child(9) {

                min-width: 80px;
                height: 80px;

            }

        }
    }

    @media(min-width: 1024px) {
        .txt-overlay {
            -webkit-text-stroke: 1px #fff;
        }

        .images {
            animation: 30s slider infinite linear;

            div:nth-child(1) {
                top: -100px;
                min-width: 80px;
                height: 80px;

            }

            div:nth-child(2) {
                bottom: 10px;
                min-width: 100px;
                height: 100px;

            }

            div:nth-child(3) {
                bottom: -60px;
                min-width: 150px;
                height: 150px;

            }

            div:nth-child(4) {

                min-width: 300px;
                height: 300px;

            }

            div:nth-child(5) {
                top: -40px;
                min-width: 80px;
                height: 80px;

            }

            div:nth-child(6) {
                bottom: 10px;
                min-width: 120px;
                height: 120px;

            }

            div:nth-child(7) {
                bottom: -60px;
                min-width: 50px;
                height: 50px;

            }

            div:nth-child(8) {

                min-width: 150px;
                height: 150px;

            }

            div:nth-child(9) {

                min-width: 150px;
                height: 150px;

            }
        }
    }

    @media(min-width: 2000px) {
        .content {
            overflow: hidden;
        }
    }



}