@import "../../core/scss/variables";

button {
    min-width: 150px;
    outline: none;
}

.border-mask {
    display: flow-root;
    position: relative;
    padding: 0;
    border-radius: 40px;
    cursor: pointer;
  }
  
  .border-mask::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 40px;
    border: 2px solid transparent;
  }

  .gradient-border-mask::before {
    background: linear-gradient(45deg, $light-blue, $sky-blue) border-box;
    -webkit-mask: linear-gradient($white 0 0) padding-box, linear-gradient($white 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  .white-border-mask::before {
    background: $white border-box;
    -webkit-mask: linear-gradient($white 0 0) padding-box, linear-gradient($white 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  
  .search-bg{
    min-width: auto;
    background:linear-gradient(to right,$light-green , $dark-blue);
  }