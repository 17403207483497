.nav-item {
  font-size: 12px;
  font-family: 'Good Times', sans-serif;
  color: white;
  margin: 0 1rem;
  position: relative;
}

.menu-bar {
  display: flex;
  width: 93%;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  display: flex;
  list-style: none;
  cursor: pointer;
}

.underline {
  height: 3px;
  background: linear-gradient(to right, #477ef9, #9dbbff, #00d94a);
  border-radius: 3px;
  width: 0;
  position: absolute;
  bottom: -0.7rem;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.3s ease-in-out;
}

.nav-item.active .underline {
  width: 30px;
}

.divider {
  display: none;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
}

.bar {
  height: 2px;
  width: 100%;
  background: linear-gradient(to right, #477ef9, #9dbbff, #00d94a);
}

.firstBar {
  width: 50%;
}

.thirdBar {
  width: 75%;
}

.mobile-menu {
  position:fixed;
  padding: 3.5rem;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background-color: rgba(13, 14, 17, 0.8); 
  backdrop-filter: blur(75px);
  -webkit-backdrop-filter: blur(75px);
  transition: left 0.3s ease-in-out;
  z-index: 20;
}

.mobile-menu.open {
  left: 0;
}

.close-icon {
  position: absolute;
  color: white;
  font-size: 30px;
  top: 0.6rem;
  left: 1.5rem;
  cursor: pointer;
}

.custom-close-icon {
  background-image: linear-gradient(to right, #477ef9, #9dbbff, #00d94a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  transform: rotate(90deg);
}

.nav-cdw-logo{
  position: relative;
}


@media (min-width: 768px) {
  .close-icon {
    display: none;
  }

  .hamburger {
    display: none;
  }

  .mobile-menu {
    position: static;
    width: auto;
    height: auto;
    background-color: transparent;
    padding: 0;
  }

  .nav-links {
    flex-direction: row;
    align-items: center;
  }

  .divider {
    display: block;
    width: 0.5px;
    height: 25px;
    margin: 0 1rem;
    border-radius: 0.5px;
    background: linear-gradient(#477ef9, #9dbbff, #00d94a);
  }
}

@media (max-width: 767px) {
  .nav-links {
    margin: 1rem 0;
    flex-direction: column;
  }

  .nav-item {
    margin: 0;
    font-size: 18px;
    padding: 1.5rem 2rem;
    position: relative;
  }

  .nav-logo{
    z-index: 21;
  }

  .nav-cdw-logo{
    z-index: 20;
  }

  .underline {
    left: 0;
    top: 49%;
    transform: translateX(-50%) rotate(90deg);
  }

  .nav-item.active .underline {
    width: 30px;
  }

  .divider {
    display: none;
  }

  .menu-bar {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .menu-bar > div:first-child {
    flex: 1;
    text-align: center;
  }

  .logo-cneter {
    width: 139px;
    margin: auto;
  }
}