@import "../../styles/mixins.scss";
.winners-container{
    height:100%;
    width: 100vw;
    position:relative;
    overflow-y: scroll;
    overflow-x: hidden;
    background-position: center;
}
.vanta-bg{
    width:100vw;
    height:100%;
    position:absolute;
    z-index:1;
    top:0px;
    left:0px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.winners-wrapper{
    width:100vw;
    height:100%;
    position:absolute;
    z-index:2;
    top:0px;
    left:0px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.gradient-border{
    border: 10px solid transparent;
    background:linear-gradient(90deg, #477EF9 0%, #9DBBFF 52.48%, #00D94A 100%)  border-box;
    /*-webkit-mask:
        linear-gradient(#fff 0 0) padding-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; */
}

.gradient-text {
    background: linear-gradient(90deg, #FFF7AA 0%, #FFED50 49.5%, #FFEFB6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.winner-list-inner-container{
    @include glass-texture;
    border-radius: 50px;
    box-shadow: 0px 0px 20px 5px #3B9CD4;
}
.image-container img{
    width:100%;
    height:100%;
    object-fit: cover;
    border-radius: 25px;
}
.logo-container img{
    width:100%;
    height:100%;
    object-fit: cover;
}
.custom-nav {
    position: absolute;
    top:58%;
    left:13.5%;
    width:1240px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
}
.dot {
    background-color:white;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }

@media(max-width:600px) {
    .winners-container{
        height:100%;
        width: 100%;
        position:fixed;
        overflow-x:hidden;
        overflow-y:scroll;
    }
    .image-container img{
        width:100%;
        height:100%;
        object-fit:cover;
        border-radius: 20px;
    }
}

@media (600px< width < 1024px){
    .winner-list-container{
        width:550px;
        height:800px;
    }
    .custom-nav{
        width:84%;
        left:8%;
        top:600px;
    }
}
@media(850px<= width <= 950px){
    .winner-list-container{
        width:750px;
        height:950px;
    }
    .winner-list-inner-container{
        width:680px;
        height:800px;
    }
    .winner-list-card{
        width:150px;
        height:220px;

    }
    .winner-list-card .image-container{
        width:150px;
        height:150px;
    }
    .custom-nav{
        width:96%;
        left:2%;
    }
}
@media(1024px<=width<=1280px){
    .winner-list-container{
        width:900px;
        height:800px;
    }
    .winner-list-inner-container{
        width:900px;
    }
    .custom-nav{
        width:98%;
        left:10px;
        top:600px
    }

}