.juries-page-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  min-height: 100%;
  z-index: 2;
  background-color: #000000;
  animation: animatedBackground 1000s linear infinite;
  display: flex;
  flex-direction: column;

  &:after {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.25;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 700%;
    background-image: url('../../assets/images/juriesImages/background/juries-bg-1.svg');
    background-position: center bottom;

    animation: animatedBk 1500s linear infinite;
    z-index: -1;
  }

  &:before {
    content: "";

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 700%;
    opacity: 0.4;
    background-image: url('../../assets/images/juriesImages/background/juries-bg-2.svg');
    background-position: center bottom;

    animation: animatedBackground 1200s linear infinite;
    z-index: 0;
  }
}

@keyframes animatedBackground {
  0% {
    background-position: 0 100%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes animatedBk {
  0% {
    background-position: 100% 100%;
  }

  50% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 100% 100%;
  }
}

.juries-page-container {
  width: 100vw;

  .juries-top-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
  }

  .juries-top-container> :first-child {
    transform: rotate(180deg);
  }

  .juries-text {
    font-family: "Good Times", sans-serif;
    background: linear-gradient(90deg, #ffffff 0%, #bcbcbc 49.5%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .glow-bar-juries {
    background: linear-gradient(to right, #477ef9, #9dbbff, #00d94a);
    border-radius: 0 1rem 1rem 0;
  }

  ::-webkit-scrollbar {
    width: 1.3rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #797979;
    transition: all 0.3s ease-in-out;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #222224;
  }

  ::-webkit-scrollbar-track {
    background: #f9f9f9;
  }

  .juries-container {
    position: relative;
  }

  .swiper {
    height: 100%;
  }

  .swiper-slide img {
    display: block;
    border-radius: 2rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }

  .juries-container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);
  }

  @media (min-width: 320px) {

    .juries-container {
      height: 60%;
      margin-top: 20px;
      transform: translate(-50%, -80%);
    }

    .border-style {
      min-width: 250px;
      min-height: 250px;
      width: 100%;
      height: 100%;
    }

    .swiper-slide img {
      width: 100%;
      height: 100%;
    }
  }


  @media (min-width: 768px) {
    .juries-container {
      height: 70%;
      transform: translate(-50%, -65%);
    }

    .border-style {
      min-width: 600px;
      min-height: 600px;
      width: 100%;
      height: 100%;
    }

    .swiper-slide img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 1024px) {
    .juries-container {
      height: 70%;
    }

    .border-style {
      min-width: 400px;
      min-height: 400px;
      width: 100%;
      height: 100%;
    }

    .swiper-slide img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 1440x) {
    .juries-container {
      height: 70%;
    }

    .swiper-slide {
      width: 600px;
      height: 600px;
    }

    .swiper-slide img {
      width: 600px;
      height: 600px;
    }
  }

  .swiper-slide-active img {
    border-radius: 2rem;
  }

  .border-style {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
  }

  .swiper-slide-active .border-style {
    position: absolute;
    overflow: hidden;
    padding: 0.25rem;

    // css for Juries details and transitions
    .jury-details {
      display: flex;
      flex-direction: column;
      color: #fff;
      transition: bottom 500ms ease-in 500ms;
      transition-delay: 500ms;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      text-transform: uppercase;
      line-height: 20px;
      letter-spacing: 0.12em;

      h1 {
        font-family: Poppins;
        font-weight: 600;
        text-align: left;
        margin-top: 5px;
        margin-bottom: 22px;
      }

      .glow-bar-juries {
        height: 3px;
        margin-left: -2.0rem;
        background: linear-gradient(to right, #477ef9, #9dbbff, #00d94a);
        border-radius: 0 1rem 1rem 0;
        width: 180px;
        opacity: 0;
        transform: scaleX(0);
        transition: opacity 1000ms ease-in, transform 500ms;
        transform-origin: right;
      }

      .designation {
        text-transform: uppercase;
        opacity: 0;
        transition: 1s;

        h2:first-child {
          padding: 0.5rem 0;
        }
      }

      &:hover {
        bottom: 0;
        transition: bottom 500ms ease-in;
        transition-delay: 200ms;

        .designation {
          opacity: 1;
          transition: 3s;
          transition-delay: 500ms;
        }

        .glow-bar-juries {
          opacity: 1;
          transform: scaleX(1);
          transform-origin: left;
          transition: opacity 500ms ease-in, transform 500ms ease-in;
          transition-delay: 500ms;
        }
      }

      &:hover+.card-background,
      &:focus-within+.card-background {
        transform: scale(1.2);
      }

      // juries detalis mobile view
      @media(min-width:320px) {
        height: 10rem;
        padding: 1rem;
        gap: 20px;
        bottom: -6rem;

        h1 {
          font-size: 20px;
        }

        .glow-bar-juries{
          width: 140px;
        }

        .designation {
          font-size: 14px;

          h2:last-child {
            font-size: 12px;
          }
        }
      }

      // juries detalis Tab view
      @media(min-width:768px) {
        padding: 3rem 3rem 2rem;
        gap: 42px;
        bottom: -8rem;
        height: 16rem;

        h1 {
          font-size: 32px;
          margin-bottom: 30px;
        }

        .glow-bar-juries {
          width: 200px;
          height: 4px;
          margin-left: -3.3rem;
        }

        .designation {
          h2:first-child {
            font-size: 26px;
            padding: 1rem 0;
          }

          h2:last-child {
            font-size: 24px;
            padding: 0.5rem 0;
          }
        }
      }

      // juries detalis Desktop view
      @media (min-width: 1024px) {
        padding: 2rem;
        gap: 42px;
        bottom: -10rem;
        height: 16rem;

        h1 {
          font-size: 28px;
          margin-bottom: 30px;
        }

        .glow-bar-juries {
          height: 3px;
          margin-left: -3.3rem;
        }

        .designation {
          h2:first-child {
            font-size: 22px;
            padding: 1rem 0;
          }

          h2:last-child {
            font-size: 20px;
            padding: 0.5rem 0;
          }
        }
      }
    }

    .card-background {
      transition: transform 500ms ease-in;
    }


    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      animation: rotate 5s linear infinite;
      background-image: linear-gradient(25deg, #01d84c 0%, #477ef9 51.5%),
        linear-gradient(transparent, transparent),
        linear-gradient(180deg, #01d84c 0%, #477ef9 51.5%),
        linear-gradient(transparent, transparent);
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border-radius: 10px;
    }

    &:hover {
      &::before {
        background: linear-gradient(180deg, #01d84c 20%, #477ef9 51.5%);
        animation: none;
      }
    }

    // &:has(.jury-details:hover){
    //   padding: 2.5rem;
    //   // border-radius: 3.9rem;
    // }
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
}

@media (min-width:320px) {
  .juries-page-container {
    animation: animatedBackground 100s linear infinite;

    &:before {
      animation: animatedBackground 150s linear infinite;

    }

    &:after {
      animation: animatedBackground 120s linear infinite;

    }
  }
}

@media (min-width:600px) {
  .juries-page-container {
    animation: animatedBackground 900s linear infinite;

    &:before {
      animation: animatedBackground 950s linear infinite;

    }

    &:after {
      animation: animatedBackground 920s linear infinite;

    }
  }
}

@media (min-width:1440px) {
  .juries-page-container {
    animation: animatedBackground 1000s linear infinite;

    &:before {
      animation: animatedBackground 1200s linear infinite;

    }

    &:after {
      animation: animatedBackground 1500s linear infinite;

    }
  }
}