// Styles for Team Card Component
@import "../../core/scss/variables";
.team-card{
  transition-property: all;
  transition-duration: 1500ms;
  -webkit-transition-property: all; 
  -webkit-transition-duration: 1500ms;
  .team-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover{
    .team-name{
      white-space: wrap;
    }
  }
}

.card-collapse {
  background-image: linear-gradient(to right, $gray-black, $gray-dark-white);
}

.card-collapse:hover {
  background-image: linear-gradient(to right, $hover-sky-blue, $hover-sky-dark-blue);
}

.card-expand {
  background-image: linear-gradient(to right, $gray-sky-blue-light, $gray-sky-blue-dark);
  backdrop-filter: blur(1.5px);
}