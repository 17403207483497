@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik+Glitch&family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Rose:wght@300..700&display=swap");

@font-face {
  font-family: "Good Times";
  src: url("./assets/fonts/goodtimes.otf") format("opentype");
}

@font-face {
  font-family: "Good Times Bold";
  src: url("./assets/fonts/GoodTimesHv-Regular.ttf");
}

@font-face {
  font-family: "Good Time Light Italic";
  src: url("./assets/fonts/GoodTimesEl-Italic.ttf");
}

@font-face {
  font-family: "Good Time Light";
  src: url("./assets/fonts/GoodTimesLt-Regular.ttf");
}

// Scss

@import "core/scss/variables";
@import "core/scss/mixins";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #000;
  height: 100vh;
  overflow: hidden;
}

div#root {
  height: 100%;
  min-height: 100%;
  position: fixed;
  overflow: scroll;
}