.antecedents-section {
    //background-image: url('../../assets/images/antecedents-bg.png');
    background-color:#1a1a1a;
    //background-repeat: no-repeat;
    //background-size: cover;
    width: 100vw;
    min-height: 100vh;
    max-height: 100%;
    height:100%;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    //background-position: center;

}
.custom-navigation {
    position: absolute;
    top:7.5%;
    left:29%;
    width:715px;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.antecedents-inner-container{
    position:relative;
    top:90px;
}
.mySwiper1{
    width:600px !important;
    height:187px !important;
    //top:7px;
}
.mySwiper1 .swiper-slide{
    overflow: hidden;
}
.mySwiper2{
    width:1125px !important;
    margin-top:480px !important;
}

@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
}

.antecedents-slide-container{
    width:100%;
    height:400px;
    overflow:hidden;
    display:flex;
    gap:25px;
    white-space: nowrap;
    position:absolute;
    z-index: 2;
    top:200px;
}

.antecedents-slide{
    display:flex;
    align-items: center;
    gap:25px;
    transform: translateX(0);
    animation:slide 10s linear infinite;
    will-change: transform;
}

.antecedents-slide img{
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: top;
    border-radius:30px;
}
@media (max-width:600px) {
    .mySwiper1{
       width:338px !important;
       height:115px !important;
       left:20px !important;
    }
    .mySwiper2{
        width: 330px !important;
        height:356px !important;
        margin-top:490px !important;
    }
    .mySwiper1 .swiper-slide {
        width: 338px !important;
        height: 115px !important;
    }

    .mySwiper2 .swiper-slide {
        width: 330px !important;
        height:256px !important;
    }

    .custom-navigation{
        top:47px;
        left:5%;
        width:94%;
    }
    .antecedents-slide-container{
        height:300px;
        gap:15px;
    }
    .antecedents-slide{
        gap:15px;
    }
}

@media (600px< width < 1024px) {
    .mySwiper1{
        width:600px !important;
        left:20px !important;
     }
     .mySwiper2{
         width: 800px !important;
         height:350px !important;
         left:5% !important;
         margin-top:70%!important;
     }
    
     .custom-navigation {
        position: absolute;
        top:5%;
        left:8%;
        width:80%;
        display: flex;
        justify-content: space-between;
        z-index: 1;
    }
    .antecedents-slide-container{
        height:360px;
        gap:20px;
    }
    .antecedents-slide{
        gap:20px;
    }
}

@media(1024px<=width<=1280px){
    .custom-navigation {
        position: absolute;
        top: 67px;
        left:10%;
        width:80%;
        display: flex;
        justify-content: space-between;
        z-index: 1;
    }
    .mySwiper2{
        margin-top: 70% !important;
        width:900px !important;
    }
    .antecedents-slide-container{
        top:300px;
    }
}